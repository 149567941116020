// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, TwitterAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirestore, initializeFirestore, persistentLocalCache, persistentSingleTabManager } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: "AIzaSyCGK1tZNQvMqQ8mpTIRG7pkS2eiQdqS_q0",
  authDomain: "elshaddai-928fd.firebaseapp.com",
  projectId: "elshaddai-928fd",
  storageBucket: "elshaddai-928fd.appspot.com",
  messagingSenderId: "749555414433",
  appId: "1:749555414433:web:8a032335a7d64874ce47e0",
  measurementId: "G-BQJPWM3VGV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore with offline persistence
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({ tabManager: persistentSingleTabManager() })
});

// Initialize other Firebase services
const auth = getAuth(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const functions = getFunctions(app);

// Initialize App Check with reCAPTCHA Enterprise
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6Lf0n_8pAAAAAKBn_Hx7ySG2jiV6DJHOJCSFVkfW'), // Site key for reCAPTCHA Enterprise
  isTokenAutoRefreshEnabled: true // Enable automatic token refresh
});

// Authentication providers
const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const linkedinProvider = new OAuthProvider('linkedin.com');
const microsoftProvider = new OAuthProvider('microsoft.com');

export {
  auth,
  googleProvider,
  twitterProvider,
  linkedinProvider,
  microsoftProvider,
  db,
  storage,
  messaging,
  functions
};
