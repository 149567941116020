// src/components/Analysis.js
import React, { useEffect, useState } from 'react';
import CustomBarChart from './BarChart';
import { getCombinedData } from '../services/firebaseService';
import { Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

const Analysis = () => {
  const [data, setData] = useState([]);
  const [selectedSede, setSelectedSede] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCombinedData();
      setData(result);
    };

    fetchData();
  }, []);

  const handleSedeChange = (e) => {
    setSelectedSede(e.target.value);
  };

  const filteredData = data.filter(sedeData => !selectedSede || sedeData.sede === selectedSede);

  const uniqueSedes = [...new Set(data.map(sedeData => sedeData.sede))];

  return (
    <div>
      <h1>Analysis of States</h1>
      <Box sx={{ minWidth: 120, marginBottom: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="sede-select-label">Filter by Sede</InputLabel>
          <Select
            labelId="sede-select-label"
            id="sede-select"
            value={selectedSede}
            label="Filter by Sede"
            onChange={handleSedeChange}
          >
            <MenuItem value="">
              <em>All Sedes</em>
            </MenuItem>
            {uniqueSedes.map((sede, index) => (
              <MenuItem key={index} value={sede}>{sede}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {filteredData.length === 0 ? (
        <p>Loading data...</p>
      ) : (
        filteredData.map((sedeData, index) => (
          <div key={index}>
            <h2>Sede: {sedeData.sede}</h2>
            {sedeData.courses.map((course, idx) => (
              <div key={idx}>
                <h3>Curso: {course.courseName}</h3>
                <h4>Attendance Data</h4>
                <CustomBarChart data={[
                  { name: 'Total Sessions', value: course.attendanceData.length },
                  { name: 'Attended', value: course.attendanceData.filter(a => a.attended).length },
                  { name: 'Not Attended', value: course.attendanceData.filter(a => !a.attended).length }
                ]} />
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default Analysis;
