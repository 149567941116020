// src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './css/Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <img src="path/to/logo.png" alt="Logo" className="sidebar-logo" />
      <nav>
        <ul>
          <li>
            <NavLink 
              to="/dashboard/home" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/dashboard/analysis" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Analysis of States
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/dashboard/notes-progress" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Notes Progress
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/dashboard/attendance-progress" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Attendance Progress
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/dashboard/ranking" 
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Ranking
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
