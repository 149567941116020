import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import PouchDB from 'pouchdb-browser';
import Swal from 'sweetalert2';

const localDB = new PouchDB('totalData');

const fetchCoursesWithSubjects = async () => {
  const coursesSnapshot = await getDocs(collection(db, 'Courses'));
  const courses = await Promise.all(coursesSnapshot.docs.map(async (courseDoc) => {
    const courseData = courseDoc.data();
    const subjectsSnapshot = await getDocs(collection(db, `Courses/${courseDoc.id}/courser`));
    const subjects = subjectsSnapshot.docs.map(subjectDoc => subjectDoc.data().subjectName);
    return { id: courseDoc.id, ...courseData, subjects };
  }));
  return courses;
};

const fetchStudentsByCourse = async () => {
  const studentCoursesSnapshot = await getDocs(collection(db, 'StudentCourse'));
  const studentCourses = studentCoursesSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return studentCourses;
};

const fetchNotesForStudents = async (studentId, courseId) => {
  const finalNotesRef = collection(db, 'finalNotes');
  const queryRef = query(
    finalNotesRef,
    where("studentId", "==", studentId),
    where("courseId", "==", courseId)
  );

  const snapshot = await getDocs(queryRef);
  if (snapshot.empty) {
    return [];
  }

  const notes = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));

  // Log the fetched notes to the console
  console.log(`Fetched notes for studentId: ${studentId}, courseId: ${courseId}`, notes);

  return notes;
};

const saveToLocalDB = async (key, data) => {
  try {
    await localDB.put({
      _id: key,
      timestamp: new Date().toISOString(),
      data
    });
  } catch (err) {
    if (err.name === 'conflict') {
      const existingDoc = await localDB.get(key);
      await localDB.put({
        ...existingDoc,
        timestamp: new Date().toISOString(),
        data
      });
    } else {
      throw err;
    }
  }
};

export const fetchAndStoreData = async () => {
  Swal.fire({
    title: 'Loading',
    text: 'Fetching data...',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const [courses, studentsByCourse] = await Promise.all([
      fetchCoursesWithSubjects(),
      fetchStudentsByCourse()
    ]);

    const finalNotesSnapshot = await getDocs(collection(db, 'finalNotes'));
    const finalNotes = finalNotesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const combinedData = await combineData(courses, studentsByCourse, finalNotes);

    await saveToLocalDB('totalData', combinedData);

    Swal.close();

    return { data: combinedData, timestamp: new Date().toISOString() };
  } catch (error) {
    Swal.fire('Error', 'Failed to fetch data', 'error');
    throw error;
  }
};

export const fetchFromLocalDB = async (key) => {
  try {
    const doc = await localDB.get(key);
    return doc;
  } catch (err) {
    if (err.name === 'not_found') {
      return null;
    } else {
      throw err;
    }
  }
};

const combineData = async (courses, studentsByCourse, finalNotes) => {
  const combinedData = [];

  for (const course of courses) {
    const students = studentsByCourse.find(sc => sc.id === course.id)?.students || [];

    const studentsWithNotes = await Promise.all(students.map(async (student) => {
      const notes = finalNotes.filter(note => note.studentId === student.id && note.courseId === course.id);
      const notesByPeriod = notes.reduce((acc, note) => {
        const period = note.period || 'Unknown Period';
        const subject = note.subjectId || 'Unknown Subject';
        const finalNote = parseFloat(note.finalNote) || 0;
        
        if (!acc[period]) acc[period] = {};
        acc[period][subject] = finalNote;

        return acc;
      }, {});
      
      // Log the notes for each student to the console
      console.log(`Notes for student: ${student.id}, course: ${course.id}`, notesByPeriod);

      return { ...student, notesByPeriod };
    }));

    combinedData.push({ ...course, students: studentsWithNotes });
  }

  return combinedData;
};
