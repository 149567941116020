// src/components/Dashboard.js
import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Home from './Home';
import Analysis from './Analysis';
import NotesProgress from './NotesProgress';
import AttendanceProgress from './AttendanceProgress';
import Ranking from './Ranking';
import './css/Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main">
        <Header />
        <div className="content">
          <Routes>
            <Route path="home" element={<Home />} />
            <Route path="analysis" element={<Analysis />} />
            <Route path="notes-progress" element={<NotesProgress />} />
            <Route path="attendance-progress" element={<AttendanceProgress />} />
            <Route path="ranking" element={<Ranking />} />
          </Routes>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
