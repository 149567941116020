import React, { useEffect, useState } from 'react';
import { fetchAndStoreData, fetchFromLocalDB } from '../services/dataService';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import Swal from 'sweetalert2';
import 'chart.js/auto';

const Home = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSede, setSelectedSede] = useState('');
  const [selectedGrado, setSelectedGrado] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      Swal.fire({
        title: 'Loading',
        text: 'Fetching data...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      try {
        const localData = await fetchFromLocalDB('totalData');
        if (localData && localData.data) {
          setData(localData.data);
          setFilteredData(localData.data);
          setLastUpdated(localData.timestamp);
        } else {
          const result = await fetchAndStoreData();
          setData(result.data || []);
          setFilteredData(result.data || []);
          setLastUpdated(result.timestamp || new Date().toISOString());
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch data', 'error');
      } finally {
        Swal.close();
      }
    };

    fetchData();
  }, []);

  const handleSedeChange = (e) => {
    const sede = e.target.value;
    setSelectedSede(sede);
    filterData(sede, selectedGrado);
  };

  const handleGradoChange = (e) => {
    const grado = e.target.value;
    setSelectedGrado(grado);
    filterData(selectedSede, grado);
  };

  const filterData = (sede, grado) => {
    let filtered = data;
    if (sede) {
      filtered = filtered.filter(course => course.sede === sede);
    }
    if (grado) {
      filtered = filtered.filter(course => course.course === grado);
    }
    setFilteredData(filtered);
  };

  const handleRefresh = async () => {
    Swal.fire({
      title: 'Loading',
      text: 'Fetching data...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const result = await fetchAndStoreData();
      setData(result.data || []);
      setFilteredData(result.data || []);
      setLastUpdated(result.timestamp || new Date().toISOString());
    } catch (error) {
      Swal.fire('Error', 'Failed to fetch data', 'error');
    } finally {
      Swal.close();
    }
  };

  const uniqueSedes = [...new Set(data.map(course => course.sede))];
  const uniqueGrados = [...new Set(data.map(course => course.course))];

  const renderCharts = () => {
    return (filteredData || []).map((course, index) => {
      const periods = ['Primer Periodo', 'Segundo Periodo', 'Tercer Periodo', 'Cuarto Periodo'];
      const periodTotals = {};

      const chartData = {
        labels: course.subjects || [],
        datasets: periods.map(period => {
          const periodData = (course.subjects || []).map(subject => {
            let totalCount = 0;
            let withNotesCount = 0;
            (course.students || []).forEach(student => {
              if (student.notesByPeriod && student.notesByPeriod[period] && student.notesByPeriod[period][subject] !== undefined) {
                withNotesCount++;
              }
              totalCount++;
            });
            const percentage = totalCount > 0 ? (withNotesCount / totalCount) * 100 : 0;
            if (!periodTotals[period]) periodTotals[period] = { totalCount: 0, withNotesCount: 0 };
            periodTotals[period].withNotesCount += withNotesCount;
            periodTotals[period].totalCount += totalCount;
            return percentage;
          });

          return {
            label: period,
            data: periodData,
            backgroundColor: getRandomColor(),
          };
        }),
      };

      const totalPercentages = periods.map(period => {
        const { totalCount, withNotesCount } = periodTotals[period] || {};
        return totalCount > 0 ? ((withNotesCount / totalCount) * 100).toFixed(2) : 'N/A';
      });

      return (
        <div key={index} style={{ marginBottom: '20px' }}>
          <Typography variant="h6">{`${course.course} (${course.category}) - ${course.sede}`}</Typography>
          <Bar data={chartData} options={{ responsive: true, plugins: { legend: { position: 'top' }}}} />
          <div style={{ marginTop: '10px' }}>
            {periods.map((period, idx) => (
              <Typography key={idx} variant="body1">{`${period}: ${totalPercentages[idx]}%`}</Typography>
            ))}
          </div>
          {course.subjects.map((subject, idx) => (
            <Typography
              key={idx}
              variant="body2"
              onClick={() => showNotesModal(course, subject)}
              style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
            >
              {subject}
            </Typography>
          ))}
        </div>
      );
    });
  };

  const showNotesModal = (course, subject) => {
    const students = course.students.filter(student => {
      return Object.values(student.notesByPeriod).some(notes => notes[subject]);
    });

    let tableHTML = '<table border="1"><tr><th>Student ID</th><th>Notes</th></tr>';
    students.forEach(student => {
      const notes = Object.values(student.notesByPeriod).map(periodNotes => periodNotes[subject] || 0).join(', ');
      tableHTML += `<tr><td>${student.id}</td><td>${notes}</td></tr>`;
    });
    tableHTML += '</table>';

    Swal.fire({
      title: `Notas de ${subject} en ${course.name}`,
      html: tableHTML,
      width: '600px',
      showCloseButton: true,
      focusConfirm: false,
    });
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Cursos y Asignaturas
      </Typography>
      <Button variant="contained" color="primary" onClick={handleRefresh} style={{ marginBottom: '20px' }}>
        Actualizar Datos
      </Button>
      {lastUpdated && (
        <Typography variant="body2" style={{ marginBottom: '20px' }}>
          Última actualización: {new Date(lastUpdated).toLocaleString()}
        </Typography>
      )}
      <Box sx={{ minWidth: 120, marginBottom: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="sede-select-label">Filter by Sede</InputLabel>
          <Select
            labelId="sede-select-label"
            id="sede-select"
            value={selectedSede}
            label="Filter by Sede"
            onChange={handleSedeChange}
          >
            <MenuItem value="">
              <em>All Sedes</em>
            </MenuItem>
            {uniqueSedes.map((sede, index) => (
              <MenuItem key={index} value={sede}>{sede}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: 120, marginBottom: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="grado-select-label">Filter by Grado</InputLabel>
          <Select
            labelId="grado-select-label"
            id="grado-select"
            value={selectedGrado}
            label="Filter by Grado"
            onChange={handleGradoChange}
          >
            <MenuItem value="">
              <em>All Grados</em>
            </MenuItem>
            {uniqueGrados.map((grado, index) => (
              <MenuItem key={index} value={grado}>{grado}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {filteredData.length === 0 ? (
        <Typography variant="body1">Loading data...</Typography>
      ) : (
        renderCharts()
      )}
    </div>
  );
};

export default Home;
