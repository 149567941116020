import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../slices/authSlice';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import './css/Login.css';  // Asegúrate de crear el archivo CSS

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);  // Obtener el usuario del estado de Redux

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const { uid, email } = user;
        dispatch(setUser({ uid, email }));
        navigate('/dashboard');  // Redirigir al dashboard si el usuario ya está autenticado
      }
    });

    return () => unsubscribe();
  }, [dispatch, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const { uid, email: userEmail } = userCredential.user;
      dispatch(setUser({ uid, email: userEmail }));
      toast.success('Login successful!');
      navigate('/dashboard');  // Redirigir al dashboard
    } catch (err) {
      setError(err.message);
      toast.error('Login failed. Please try again.');
    }
  };

  // Mostrar un mensaje de carga si el estado de autenticación está siendo verificado
  if (user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="https://firebasestorage.googleapis.com/v0/b/elshaddai-928fd.appspot.com/o/siteLogos%2Funique-configuration-id?alt=media&token=ecd08e68-3885-455a-8621-99f1913d55fb" alt="Logo" className="logo"/>
        <form onSubmit={handleLogin}>
          <input 
            type="email" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <input 
            type="password" 
            placeholder="Password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          {error && <p className="error">{error}</p>}
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
