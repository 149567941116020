// src/components/Header.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { clearUser } from '../slices/authSlice';
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import './css/Header.css'; // Asegúrate de crear el archivo CSS

const Header = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await signOut(auth);
    dispatch(clearUser());
  };

  return (
    <header className="header">
      <input type="text" placeholder="Search..." className="search-bar" />
      <button onClick={handleLogout} className="logout-button">Logout</button>
    </header>
  );
};

export default Header;
