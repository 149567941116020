import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from 'sweetalert2';
import PouchDB from 'pouchdb-browser';

const localDB = new PouchDB('calculatedResults');
const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 horas en milisegundos

// Función para obtener los estudiantes y sus cursos
export const getStudentsByCourse = async () => {
  const studentCoursesSnapshot = await getDocs(collection(db, 'StudentCourse'));
  const studentCourses = studentCoursesSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return studentCourses;
};

// Función para obtener las notas finales
export const getFinalNotes = async () => {
  const finalNotesSnapshot = await getDocs(collection(db, 'finalNotes'));
  const finalNotes = finalNotesSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return finalNotes;
};

// Función para obtener los cursos y sus sedes
export const getCourses = async () => {
  const coursesSnapshot = await getDocs(collection(db, 'Courses'));
  const courses = coursesSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return courses;
};

// Función para obtener los datos combinados
export const getCombinedData = async () => {
  // Verificar si estamos en el entorno de desarrollo
  const isDevelopment = window.location.hostname === 'localhost' && window.location.port === '3000';

  if (!isDevelopment) {
    try {
      const cacheDoc = await localDB.get('combinedData');
      const now = new Date();
      const lastCalculated = new Date(cacheDoc.timestamp);

      if (now - lastCalculated < CACHE_DURATION) {
        // Si los datos en caché son válidos, devolverlos
        return cacheDoc.data;
      }
    } catch (err) {
      if (err.name !== 'not_found') {
        throw err;
      }
    }
  }

  Swal.fire({
    title: 'Loading',
    text: 'Combining data...',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const [studentsByCourse, finalNotes, courses] = await Promise.all([
    getStudentsByCourse(),
    getFinalNotes(),
    getCourses()
  ]);

  const courseMap = new Map();
  courses.forEach(course => {
    if (!courseMap.has(course.sede)) {
      courseMap.set(course.sede, []);
    }
    courseMap.get(course.sede).push(course);
  });

  const result = [];
  courseMap.forEach((courses, sede) => {
    const coursesData = courses.map(course => {
      const students = studentsByCourse.find(sc => sc.id === course.id)?.students || [];
      const notesByPeriod = finalNotes
        .filter(note => note.courseId === course.id && !isNaN(parseFloat(note.finalNote)))  // Filtrar solo notas válidas
        .reduce((acc, note) => {
          const finalNoteValue = parseFloat(note.finalNote);
          if (!acc[note.period]) acc[note.period] = {};
          if (!acc[note.period][note.subjectId]) acc[note.period][note.subjectId] = [];
          if (!isNaN(finalNoteValue)) acc[note.period][note.subjectId].push({ ...note, finalNote: finalNoteValue });
          return acc;
        }, {});

      const periods = Object.keys(notesByPeriod).map(period => {
        const subjects = Object.keys(notesByPeriod[period]).map(subject => {
          const totalStudents = students.length;
          const studentsWithNotes = new Set(notesByPeriod[period][subject].map(note => note.studentId)).size;
          const percentageWithNotes = totalStudents > 0 ? (studentsWithNotes / totalStudents) * 100 : 0;
          return {
            subject,
            percentageWithNotes
          };
        });

        return { period, subjects };
      });

      return {
        courseName: `${course.course} (${course.category})`,
        totalStudents: students.length,
        periods,
      };
    });

    result.push({
      sede,
      courses: coursesData
    });
  });

  Swal.close();

  // Almacenar los resultados en PouchDB si no estamos en el entorno de desarrollo
  if (!isDevelopment) {
    await localDB.put({
      _id: 'combinedData',
      timestamp: new Date().toISOString(),
      data: result
    });
  }

  return result;
};
