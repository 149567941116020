// src/components/ProtectedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectUser } from '../slices/authSlice';

const ProtectedRoute = ({ component: Component }) => {
  const user = useSelector(selectUser);

  return user ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
