// src/components/AttendanceProgress.js
import React from 'react';

const AttendanceProgress = () => {
  return (
    <div>
      <h1>Attendance Progress</h1>
      <p>Progress of attendance details go here.</p>
    </div>
  );
};

export default AttendanceProgress;
